/* eslint-disable no-restricted-globals */
import { useState, useRef } from "react";
import DataTable from "../Table/Datatable.js";
import { format as dateFormat, set } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main.js";
import "./reach.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye, filterStatsDate, filterStatsPartner } from "./filters.js";
import { Modal, Box, Typography, MenuItem } from "@material-ui/core";
import { FormControl, InputLabel, Select, ListSubheader, InputAdornment, TextField, Checkbox, Button } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo.js";
import { Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { regionsData } from "./geolist.js";
import Chip from "@mui/material/Chip";

const styles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const postviewStyles = {
  maxHeight: "800px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "80%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const decisionStyles = {
  maxHeight: "550px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};


const defaultStyles = {
  maxHeight: "800px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  padding: "32px",
};


const campaignStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};


const RegionSelect = ({ defaultValue, regions, handleChange, campaign_id }) => {
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (defaultValue) {
      setSelectedRegions(defaultValue.split(","));
    }
  }, [defaultValue]);

  const toggleRegion = (regionId) => {
    setSelectedRegions((prevSelected) => {
      const newSelected = prevSelected.includes(regionId)
        ? prevSelected.filter((id) => id !== regionId)
        : [...prevSelected, regionId];
      handleChange("ssp_geo", newSelected.join(","), campaign_id);
      return newSelected;
    });
  };

  const selectAll = () => {
    const allRegionIds = Object.keys(regions);
    setSelectedRegions(allRegionIds);
    handleChange("ssp_geo", allRegionIds.join(","), campaign_id);
  };

  const deselectAll = () => {
    setSelectedRegions([]);
    handleChange("ssp_geo", "", campaign_id);
  };

  const filteredRegions = Object.entries(regions).filter(([id, name]) =>
    name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRemoveRegion = (regionId) => {
    setSelectedRegions((prevSelected) => prevSelected.filter((id) => id !== regionId));
    handleChange("ssp_geo", selectedRegions.join(","), campaign_id);
  };

  return (
    <Box sx={{ width: 230, padding: "10px 0", textAlign: "start" }}>
      <FormControl fullWidth size="small">
        <InputLabel>Выбор региона</InputLabel>
        <Select
          multiple
          onOpen={() => setTimeout(() => document.getElementById("search-input-regions").focus(), 100)}
          value={selectedRegions}
          renderValue={(selected) => selected.map((id) => regions[id]).join(", ")}
          style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
          sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
          onClose={() => setTimeout(() => setSearchQuery(""), 100)}
        >
          <ListSubheader>
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                mb: 1,
                maxWidth: "500px",
                maxHeight: "300px",
                overflowY: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none"
              }}>
              {selectedRegions.map((regionId) => (
                <Chip
                  key={regionId}
                  label={regions[regionId]}
                  onDelete={() => handleRemoveRegion(regionId)}
                  style={{ border: "2px solid #3f73f994", borderRadius: "15px", margin: "3px", color: "black" }}
                />
              ))}
            </Box>
            <Box sx={{ display: "flex", gap: 5, mb: 1 }}>
              <button onClick={selectAll} style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", fontSize: 14, borderRadius: 5 }}>
                Выбрать все
              </button>
              <button onClick={deselectAll} style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", fontSize: 14, borderRadius: 5 }}>
                Снять все
              </button>
            </Box>
            <TextField
              size="small"
              placeholder="Поиск"
              fullWidth
              autoFocus
              value={searchQuery}
              id="search-input-regions"
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  setTimeout(() => document.getElementById("search-input-regions").focus(), 100);
                }
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {filteredRegions.map(([id, name]) => (
            <MenuItem key={id} value={id} onClick={() => toggleRegion(id)}>
              <Checkbox checked={selectedRegions.includes(id)} style={{ pointerEvents: "none", marginRight: "8px" }} />
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};



const CampaignData = ({data}) => {
  const { enqueueSnackbar } = useSnackbar();
  const listCampaigns = []
  listCampaigns.push(data)

  const columns = [
    {
      Header: "ID РК",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Название",
      accessor: "name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Почта клиента",
      accessor: "campaign_creator_email",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Дата создания",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Тип РК",
      accessor: "type",
      maxWidth: 400,
      minWidth: 60,
      width: 117,
    },
    {
      Header: "Дневной бюджет",
      accessor: "daily_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Общий бюджет",
      accessor: "full_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Ставка",
      accessor: "bet",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Период с-по",
      accessor: "range",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сумма спис",
      accessor: "charge",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 400,
      minWidth: 50,
      width: 200,
    },
  ];

  const STATUS_TYPE = {
    ACTIVE: "На модерации",
    INACTIVE: "Неактивна",
    WORK: "Работает",
    MODERATION: "На модерации",
    ERROR: "Ошибка",
  };
  
  const TARGET_TYPE = {
    TRAFFIC: "Трафик",
    REACH: "Охват",
  };

  function getTableData() {
    return listCampaigns.map((el) => {
      return {
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            {el.id.slice(0, 8)}
            <ContentCopy
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(el.id);
                enqueueSnackbar("ID кампании скопирован", {
                  variant: "success",
                  autoHideDuration: 1000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }}
              className="copyIcon"
              fontSize="small"
            />
          </div>
        ),
        name: <div className="td">{el.name ? el.name : "-"}</div>,
        site: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.site ? el.site : "-"}</span>
            {el.site && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.site);
                  enqueueSnackbar("Сайт кампании скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        campaign_creator_email: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.campaign_creator_email ? el.campaign_creator_email : "-"}</span>
            {el.campaign_creator_email && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.campaign_creator_email);
                  enqueueSnackbar("Почта клиента скопирована", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        created_at: (
          <div className="td">
            {dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}
          </div>
        ),
        type: <div className="td">{TARGET_TYPE[el.target_type]}</div>,
        daily_budget: <div className="td">{el.daily_budget}</div>,
        full_budget: <div className="td">{el.full_budget}</div>,
        bet: <div className="td">{el.price_per_action}</div>,
        range: (
          <div className="td">
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_start)),
              "dd/MM/yyyy"
            )}
            -
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_end)),
              "dd/MM/yyyy"
            )}
          </div>
        ),
        charge: <div className="td">{el.charges_sum}</div>,
        status: (
          <div className="td">
            {STATUS_TYPE[el.status]}
          </div>
        ),
      };
    });
  }

  return (
    <DataTable data={getTableData()} columns={columns} />
  )
}


const getMoscowMidnight = (date) => {
  const targetDate = new Date(date);
  
  const year = targetDate.getFullYear();
  const month = String(targetDate.getMonth() + 1).padStart(2, '0');
  const day = String(targetDate.getDate()).padStart(2, '0');

  const moscowMidnight = new Date(`${year}-${month}-${day}T00:00:00+03:00`);

  return Math.floor(moscowMidnight.getTime() / 1000);
};


const PostViewData = ({ campaignData }) => {
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [dataList, setDataList] = useState([]);
  const [upd, setUpd] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [goalsList, setGoalsList] = useState([]);
  const [searchGoals, setSearchGoals] = useState('');
  const [selectedGoal, setSelectedGoal] = useState("all");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const response = await fetch(`${API_HOST}/api/v1/admin/get-goals?campaign_id=${campaignData.id}`, {
        method: "GET",
        headers: myHeaders
      });
      const data = await response.json();
      setGoalsList(data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      console.log(filtersValue.date);
  
      const date0 = getMoscowMidnight(new Date(filtersValue.date.date0));
      const date1 = getMoscowMidnight(new Date(new Date(filtersValue.date.date1).setDate(new Date(filtersValue.date.date1).getDate() + 1)));

      console.log(date0, date1);
      const response = await fetch(`${API_HOST}/api/v1/admin/get-post-views?campaign_id=${campaignData.id}&page=${currentPage}&limit=10`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          goal_id: selectedGoal !== "all" ? selectedGoal : undefined,
          date0: date0,
          date1: date1,
        })
      });
  
      if (response.ok) {
        const data = await response.json();
        setDataList(data.data);
        setTotalPages(data.total_pages);
      }
    }
    if (filtersValue.date && Object.values(filtersValue.date).some(date => date !== "")) {
      fetchData();
    }
  }, [upd, currentPage]);

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const columns = [
    {
      Header: "",
      accessor: "is_fake",
      maxWidth: 400,
      minWidth: 80,
      width: 50,
      isSticky: true,
    },
    {
      Header: "ID Конверсии",
      accessor: "conversionId",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Название конверсии",
      accessor: "conversionType",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
      isSticky: true,
    },
    {
      Header: "Дата конверсии",
      accessor: "activityDateTime",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата визита",
      accessor: "visitDateTime",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата показа",
      accessor: "interactionDateTime",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "ID Кампании",
      accessor: "campaignId",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Название кампании",
      accessor: "campaignName",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Статус события показ/клик",
      accessor: "eventType",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ID Баннера",
      accessor: "bannerId",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Трекинг система",
      accessor: "trackingSystem",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Домен показа",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "ОС",
      accessor: "OS",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "Устройство",
      accessor: "device",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "География",
      accessor: "region",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Браузер",
      accessor: "browser",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Programmatic ID",
      accessor: "programmaticId",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "clientID YM",
      accessor: "clientId",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "",
      accessor: "delete",
      maxWidth: 400,
      minWidth: 50,
      width: 50,
      isStickyRight: true,
    },
  ];

  const handleDelete = (post_view_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("admin_token"));
    fetch(`${API_HOST}/api/v1/admin/minus?post_view_id=${post_view_id}`, {
      method: "GET",
      headers: myHeaders,
    }).then((response) => {
      if (response.ok) {
        setUpd(!upd);
        enqueueSnackbar("Post-View успешно удалена", {
          variant: "success",
          autoHideDuration: 1000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    });
  }

  const handleDateChange = (value, type) => {
    setValue((prev) => ({
      ...prev,
      date: type === 1
        ? { ...prev.date, date0: value[0] ?? "", date1: value[1] ?? "" }
        : { ...prev.date, date2: value[0] ?? "", date3: value[1] ?? "" },
    }));
  };

  const formatDateTime = (date, istext = false) => {
    if (!date) return;
    const d = new Date(date); 
    if (isNaN(d.getTime())) return;
  
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
  
    if (istext) {
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    }
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  function getTableData(data) {
    if (Object.keys(data).length === 0) {
      return [
        {
          is_fake: <div className="td"><span style={{ backgroundColor: "#a4bac7", width: 10, height: 10, display: "inline-block", borderRadius: "50%" }}/></div>,
          conversionId: <div className="td">-</div>,
          conversionType: <div className="td">-</div>,
          activityDateTime: <div className="td">-</div>,
          visitDateTime: <div className="td">-</div>,
          interactionDateTime: <div className="td">-</div>,
          campaignId: <div className="td">-</div>,
          campaignName: <div className="td">-</div>,
          eventType: <div className="td">-</div>,
          bannerId: <div className="td">-</div>,
          trackingSystem: <div className="td">-</div>,
          domain: <div className="td">-</div>,
          OS: <div className="td">-</div>,
          device: <div className="td">-</div>,
          region: <div className="td">-</div>,
          browser: <div className="td">-</div>,
          programmaticId: <div className="td">-</div>,
          clientId: <div className="td">-</div>,
          delete: <div className="td"></div>,
        },
      ];
    }
  
    return data
      .sort((a, b) => (a.conversion_datetime > b.conversion_datetime ? -1 : 1))
      .map((row) => {
        return {
          is_fake: <div className="td"><span style={{ backgroundColor: row.is_fake ? "#f7b807" : "#05d005", width: 10, height: 10, display: "inline-block", borderRadius: "50%" }}/></div>,
          conversionId: <div className="td">{row.conversion_id || "-"}</div>,
          conversionType: <div className="td">{row.conversion_type || "-"}</div>,
          activityDateTime: <div className="td">{formatDateTime(row.conversion_datetime, true) || "-"}</div>,
          visitDateTime: <div className="td">{formatDateTime(row.last_visit_before_conversion, true) || "-"}</div>,
          interactionDateTime: <div className="td">{formatDateTime(row.view_datetime, true) || "-"}</div>,
          campaignId: <div className="td">{row.campaign_id || "-"}</div>,
          campaignName: <div className="td">{row.campaign_name || "-"}</div>,
          eventType: <div className="td">{row.event_type || "-"}</div>,
          bannerId: <div className="td">{row.banner_id || "-"}</div>,
          trackingSystem: <div className="td">{row.tracking_system || "-"}</div>,
          domain: <div className="td">{row.domain || "-"}</div>,
          OS: <div className="td">{row.os || "-"}</div>,
          device: <div className="td">{row.device || "-"}</div>,
          region: <div className="td">{row.region || "-"}</div>,
          browser: <div className="td">{row.browser || "-"}</div>,
          programmaticId: <div className="td">{row.programmatic_id || "-"}</div>,
          clientId: <div className="td">{row.client_id || "-"}</div>,
          delete: row.is_fake ? (
            <div
              className="td ban"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  const answer = await confirm("Вы подтверждаете удаление Post-View ?")
                  if (!answer) return;
                  handleDelete(row.id)
                }}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3327 0.666016L0.666016 11.3327M11.3327 11.3327L0.666016 0.666016L11.3327 11.3327Z"
                  stroke="#42454C"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          ) : (
            <div className="td"></div>
          )
        };
      });
  }

  const filteredGoalsList = goalsList.filter((goal) =>
    goal.name?.toLowerCase().includes(searchGoals.toLowerCase())
  );
  
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <Box sx={{ width: "fit-content", minWidth: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Выбор цели
            </InputLabel>
            <Select
              onOpen={() => setTimeout(() => document.getElementById("search-input-goals").focus(), 100)}
              defaultValue={selectedGoal || "all"}
              onChange={(e) => setSelectedGoal(e.target.value)}
              style={{
                height: 32,
                margin: "0 10px",
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="CallTracking"
              id="demo-select-small"
              onClose={() => setTimeout(() => setSearchGoals(""), 300)}
            >
              <ListSubheader>
                <TextField
                  id="search-input-goals"
                  size="small"
                  autoFocus
                  placeholder="Поиск"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchGoals(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      setTimeout(() => document.getElementById("search-input-goals").focus(), 100)
                    }
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem value="all">Все цели</MenuItem>
              {filteredGoalsList.map((goal) => (
                <MenuItem key={goal._id} value={goal._id}>
                  {goal.name} - {goal._id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <button
          style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "3px 15px", fontSize: 14, borderRadius: 5 }}
          onClick={async () => {
            const answer = await confirm("Вы подтверждаете добавление Post-View ?")
            if (!answer) return;
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("admin_token")
            );

            if (selectedGoal === "all") {
              return enqueueSnackbar("Выберите определенную цель", {
                variant: "info",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }

            const response = await fetch(
              `${API_HOST}/api/v1/admin/plus?campaign_id=${campaignData.id}${selectedGoal !== "all" ? `&goal_id=${selectedGoal}` : ""}`,
              {
                method: "GET",
                headers: myHeaders,
              }
            );
          
            if (response.ok) {
              setUpd(!upd);
              enqueueSnackbar("Доп. Post-View по цели успешно добавлена.", {
                variant: "success",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            } else {
              enqueueSnackbar("Ошибка в добавлении доп. Post-View", {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          }}
          >
            +1 доп. Post-view
        </button>
      </div>

      <div style={{ width: "400px" }}>
        <Typography style={{ marginBottom: 10, fontSize: 14 }} id="modal-modal-title" variant="h5" component="h4">
          Промежуток времени совершенных показов
        </Typography>
        <DateFromTo
          from={new Date(new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000).getTime()}
          to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
          handleChange={(value) => handleDateChange(value, 1)}
        />
      </div>

      <div>
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <h3 style={{ fontSize: 18 }}>Таблица</h3>
          <div style={{ display: "flex", gap: 10 }}>
            <button
              style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "3px 15px", fontSize: 14, borderRadius: 5 }}
              onClick={() => {
                setUpd((prev) => !prev);
                enqueueSnackbar("Запрос на обновление таблицы.", {
                  variant: "success",
                  autoHideDuration: 1000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }}
              >
                Обновить
            </button>
            <button
              style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "3px 15px", fontSize: 14, borderRadius: 5 }}
              onClick={async () => {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                  "Authorization",
                  "Bearer " + localStorage.getItem("admin_token")
                );
          
                const date0 = getMoscowMidnight(new Date(filtersValue.date.date0));
                const date1 = getMoscowMidnight(new Date(new Date(filtersValue.date.date1).setDate(new Date(filtersValue.date.date1).getDate() + 1)));
          
                  const response = await fetch(
                    `${API_HOST}/api/v1/admin/post-views/export/${campaignData.id}?date_from=${date0}&date_to=${date1}&extra=true${selectedGoal !== "all" ? `&goal_id=${selectedGoal}` : ""}`,
                    {
                      method: "GET",
                      headers: myHeaders,
                    }
                  );
              
                  if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
              
                    link.download = `post_view_report_${campaignData.name}.xlsx`;
                    document.body.appendChild(link);
                    link.click();
              
                    link.remove();
                    window.URL.revokeObjectURL(url);
                  } else {
                    console.error("Ошибка при скачивании файла:", response.statusText);
                  }
                }}
              >
                Скачать
            </button>
          </div>
        </div>
        {
          dataList.length > 0 ? (
            <div style={{ display: "flex", flexDirection: "column", gap: "15px", marginTop: "30px", overflowX: "auto", scrollbarWidth: "none", '-ms-overflow-style': 'none' }}>
              <DataTable data={getTableData(dataList)} columns={columns} />
            </div>
          ) : (
            <div style={{ width: "900px", display: "flex", height: "30px", justifyContent: "center", alignItems: "center" }}>
              Данные не найдены.
            </div>
          )
        }
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 5, marginTop: 20 }}>
          <button
            style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "3px 15px", fontSize: 14, borderRadius: 5 }}
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1}
          >
            {"Назад"}
          </button>
          <h4 style={{ letterSpacing: 1.5 }}>
            {currentPage} / {totalPages}
          </h4>
          <button
            style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "3px 15px", fontSize: 14, borderRadius: 5 }}
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages}
          >
            {"Дальше"}
          </button>
        </Box>
      </div>
    </div>
  );
};



const DatePickerTest = ({ defaultValue, value, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue || '');

  useEffect(() => {
    const isTimestamp = (input) => 
      typeof input === 'number' || /^\d{10,}$/.test(String(input));
  
    if (value !== undefined && value !== null) {
      setSelectedDate(isTimestamp(value) ? formatDatePicker(value) : value);
    } else if (defaultValue) {
      setSelectedDate(isTimestamp(defaultValue) ? formatDatePicker(defaultValue) : defaultValue);
    }
  }, [value, defaultValue]);
  
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    
    if (value === undefined || value === null) {
      setSelectedDate(newDate);
    }
    
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <div style={stylesDatePicker.datepickerContainer}>
      <input 
        type="date" 
        value={selectedDate} 
        onChange={handleDateChange} 
        style={stylesDatePicker.datepickerInput}
      />
    </div>
  );
};



const formatDatePicker = (date, istext = false) => {
  if (date === 0 || !date) return;
  const d = new Date(date * 1000);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  if (istext) return `${day}.${month}.${year}`
  return `${year}-${month}-${day}`;
};

const stylesDatePicker = {
  datepickerContainer: {
    padding: "5px 0 0 0",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datepickerInput: {
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.3s ease',
    ':hover': {
      borderColor: '#888',
    },
    ':focus': {
      borderColor: '#007bff',
    },
  },
};



const BannersStatsData = ({ campaignData }) => {
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [filterArr, setFilter] = useState([]);
  const [upd, setUpd] = useState(false);
  const [tableType, setTableType] = useState("geo");

  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState("all");

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );

      const date_start =
        new Date(filtersValue.date.date_start).setHours(0, 0, 0, 0) / 1000;
      const date_end =
        new Date(filtersValue.date.date_end).setHours(23, 59, 59, 0) / 1000;

      switch (tableType) {
        case "details": {
          const detailResponse = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details/${campaignData.id}?target_type=REACH&date_from=${date_start}&date_to=${date_end}` + (partner === "all" ? "" : `&partner_name=${partner}`),
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (detailResponse.ok) {
            const data = await detailResponse.json();
            setFilter(data);
          }
          break;
        }
        case "domain": {
          const domainResponse = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details_by_domain/${campaignData.id}?date_from=${date_start}&date_to=${date_end}` + (partner === "all" ? "" : `&partner_name=${partner}`),
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (domainResponse.ok) {
            const data = await domainResponse.json();
            setFilter(data);
          }
          break;
        }
        case "finance": {
          const response = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details_by_finance/${campaignData.id}?date_from=${date_start}&date_to=${date_end}` + (partner === "all" ? "" : `&partner_name=${partner}`),
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (response.ok) {
            const data = await response.json();
            setFilter(data);
          }
          break;
        }
        case "geo": {
          const geoResponse = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details_by_geo/${campaignData.id}?date_from=${date_start}&date_to=${date_end}` + (partner === "all" ? "" : `&partner_name=${partner}`),
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (geoResponse.ok) {
            const data = await geoResponse.json();
            setFilter(data);
          }
          break;
        }
        default:
          break;
      }
    }

    if (filtersValue.date.date_start && filtersValue.date.date_end) {
      fetchData();
    }
    console.log(tableType)
  }, [filtersValue.date.date_start, filtersValue.date.date_end, upd, tableType]);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const response = await fetch(
        `${API_HOST}/api/v1/admin/ssp-partner-net/fetch`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setPartners(data);
      }
    }
    fetchData();
  }, []);

  const detailColumns = [
    {
      Header: "Дата и время",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "IP",
      accessor: "remote_address",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сайт",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "CPM ставка",
      accessor: "cost",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function detailGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        created_at: <div className="td">{el.created_at ? dateFormat(new Date(el.created_at), "dd.MM.yyyy HH:mm:ss") : "-"}</div>,
        remote_address: <div className="td">{el.remote_address ? el.remote_address : "-"}</div>,
        domain: <div className="td">{el.domain ? el.domain : "Нет"}</div>,
        geo: <div className="td">{regionsData[el.geo] || el.geo || "Нет"}</div>,
        cost: <div className="td">{el.cost ? el.cost + " руб." : "-"}</div>,
      };
    });
  }

  const geoColumns = [
    {
      Header: "Гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Показы",
      accessor: "impressions",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Просмотры",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function geoGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        geo: <div className="td">{regionsData[el.geo] || el.geo || "Нет"}</div>,
        impressions: <div className="td">{el.impressions ? el.impressions : "0"}</div>,
        clicks: <div className="td">{el.clicks ? el.clicks : "0"}</div>,
        ctr: <div className="td">{el.ctr ? Number(el.ctr).toFixed(2) + "%" : "0%"}</div>,
      };
    });
  }

  const domainColumns = [
    {
      Header: "Сайт",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Показы",
      accessor: "impressions",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Просмотры",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "eCPM",
      accessor: "ecpm",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Затраты",
      accessor: "total_cost",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function domainGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        domain: <div className="td">{el.domain ? el.domain : "Нет"}</div>,
        impressions: <div className="td">{el.impressions ? el.impressions : "0"}</div>,
        clicks: <div className="td">{el.clicks ? el.clicks : "0"}</div>,
        ctr: <div className="td">{el.ctr ? Number(el.ctr).toFixed(2) + "%" : "0%"}</div>,
        ecpm: <div className="td">{el.average_cpm ? el.average_cpm : "0"}</div>,
        total_cost: <div className="td">{el.total_cost ? el.total_cost : "0"}</div>,
      };
    });
  }


  const financeColumns = [
    {
      Header: "Дата",
      accessor: "date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Показы",
      accessor: "impressions",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Клики",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "eCPM",
      accessor: "ecpm",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Затраты",
      accessor: "total_cost",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function financeGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        date: <div className="td">{el.date ? dateFormat(new Date(el.date), "dd.MM.yyyy") : "-"}</div>,
        impressions: <div className="td">{el.impressions ? el.impressions : "0"}</div>,
        clicks: <div className="td">{el.clicks ? el.clicks : "0"}</div>,
        ctr: <div className="td">{el.ctr ? Number(el.ctr).toFixed(2) + "%" : "0%"}</div>,
        ecpm: <div className="td">{el.average_cpm ? el.average_cpm : "0"}</div>,
        total_cost: <div className="td">{el.total_cost ? el.total_cost : "0"}</div>,
      };
    });
  }

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };



  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "400px" }}>
          <DateFromTo
            from={new Date(new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000).getTime()}
            to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
            handleChange={handleDateChange}
          />
        </div>
        <button
          style={{ marginLeft: 80, width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 20px", fontSize: 14, borderRadius: 5 }}
          onClick={async () => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("admin_token")
            );
      
            const date_start =
              new Date(filtersValue.date.date_start).setHours(0, 0, 0, 0) / 1000;
            const date_end =
              new Date(filtersValue.date.date_end).setHours(23, 59, 59, 0) / 1000;
      
              const response = await fetch(
                `${API_HOST}/api/v1/ssp-stats/export/${campaignData.id}?date_from=${date_start}&date_to=${date_end}&breakdown_type=${tableType}` + (partner === "all" ? "" : `&partner_name=${partner}`),
                {
                  method: "GET",
                  headers: myHeaders,
                }
              );
          
              if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
          
                link.download = `${tableType}_statistics_${campaignData.name}.xlsx`;
                document.body.appendChild(link);
                link.click();
          
                link.remove();
                window.URL.revokeObjectURL(url);
              } else {
                console.error("Ошибка при скачивании файла:", response.statusText);
              }
            }}
          >
            Скачать
        </button>
      </div>
      <div style={{ display: "flex", gap: 20 }}>
        <Box sx={{ width: 175 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Тип отчёта
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Партнёр"
              id="demo-select-small-label"
              defaultValue={"geo"}
              onChange={(e) => {
                console.log(e.target.value);
                setTableType(e.target.value);
                setUpd(!upd);
              }}
            >
              <MenuItem value={"details"}>Детальный</MenuItem>
              <MenuItem value={"geo"}>По гео</MenuItem>
              <MenuItem value={"domain"}>По доменам</MenuItem>
              <MenuItem value={"finance"}>По финансам</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: 175 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              По партнёру
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Партнёр"
              id="demo-select-small-label"
              defaultValue={"all"}
              onChange={(e) => {
                console.log(e.target.value);
                setPartner(e.target.value);
                setUpd(!upd);
              }}
            >
              <MenuItem value={"all"}>Все</MenuItem>
              {partners.map((item) => (
                <MenuItem key={item.id} value={item.name}>{item.display_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>

      {
        tableType === "details" && (
          detailGetTableData().length > 0 ? (
            <DataTable data={detailGetTableData()} columns={detailColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }

      {
        tableType === "geo" && (
          geoGetTableData().length > 0 ? (
            <DataTable data={geoGetTableData()} columns={geoColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }

      {
        tableType === "domain" && (
          domainGetTableData().length > 0 ? (
            <DataTable data={domainGetTableData()} columns={domainColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }

      {
        tableType === "finance" && (
          financeGetTableData().length > 0 ? (
            <DataTable data={financeGetTableData()} columns={financeColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }
    </div>
  );
};


const GoalPicker = ({ goalsList, fieldName, handleChange, defaultValue, campaignId }) => {
  const [searchGoals, setSearchGoals] = useState('');
  const [selectedGoal, setSelectedGoal] = useState(defaultValue || "nope");

  const filteredGoalsList = goalsList.filter((goal) =>
    goal.name?.toLowerCase().includes(searchGoals.toLowerCase())
  );

  return (
    <Box sx={{ width: "300px", textAlign: "start", padding: "2px 0", height: 30 }}>
      <FormControl fullWidth size="small">
        <InputLabel
          id="demo-select-small-label"
          sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
        >
          Выбор цели
        </InputLabel>
        <Select
          onOpen={() => setTimeout(() => document.getElementById("search-input-goals").focus(), 100)}
          defaultValue={selectedGoal || "nope"}
          onChange={(e) => {
            setSelectedGoal(e.target.value);
            handleChange(fieldName, String(e.target.value), campaignId);
          }}
          style={{
            height: 32,
            margin: "0 10px",
            background: "#FFFFFF",
            borderColor: "transparent",
          }}
          sx={{
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          }}
          label="CallTracking"
          id="demo-select-small"
          onClose={() => setTimeout(() => setSearchGoals(""), 300)}
        >
          <ListSubheader>
            <TextField
              id="search-input-goals"
              size="small"
              autoFocus
              placeholder="Поиск"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchGoals(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  setTimeout(() => document.getElementById("search-input-goals").focus(), 100)
                }
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem value="nope">Не выбрано</MenuItem>
          {filteredGoalsList.map((goal) => (
            <MenuItem key={goal._id} value={goal._id}>
              {goal.name} - {goal._id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}


const SettingsPostView = ({ campaignData }) => {
  const [goalsList, setGoalsList] = useState([]);

  const debounceTimers = useRef({});
  const updateQueues = useRef({});
  const isUpdating = useRef({});


  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const response = await fetch(`${API_HOST}/api/v1/admin/get-goals?campaign_id=${campaignData.id}`, {
        method: "GET",
        headers: myHeaders
      });
      const data = await response.json();
      setGoalsList(data);
    }
    fetchData();
  }, []);


  const debounceUpdate = (field, campaign_id, callback, delay = 500) => {
    const uniqueKey = `${field}-${campaign_id}`;
    clearTimeout(debounceTimers.current[uniqueKey]);

    debounceTimers.current[uniqueKey] = setTimeout(() => {
        if (!updateQueues.current[uniqueKey]) updateQueues.current[uniqueKey] = [];
        updateQueues.current[uniqueKey].push(callback);
        processUpdateQueue(uniqueKey);
    }, delay);
  };

  const processUpdateQueue = async (uniqueKey) => {
      if (isUpdating.current[uniqueKey] || !updateQueues.current[uniqueKey] || updateQueues.current[uniqueKey].length === 0) return;

      isUpdating.current[uniqueKey] = true;

      while (updateQueues.current[uniqueKey].length > 0) {
          const callback = updateQueues.current[uniqueKey].shift();
          await callback();
      }

      isUpdating.current[uniqueKey] = false;
  };

  const handleChange = (field, value, campaign_id) => {
      debounceUpdate(field, campaign_id, async () => {
          console.log(field, value, campaign_id);
          await updateFields({ [field]: value }, campaign_id);
      });
  };

  const updateFields = async (fields, campaign_id) => {
      if (campaign_id === null) return;
      console.debug(fields, campaign_id);
      await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/update_ssp`, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
          },
          body: JSON.stringify(fields),
      });
  };


  const FormatNumberInput = ({ el, handleChange, fieldName }) => {
    const [formattedValue, setFormattedValue] = useState('');
  
    useEffect(() => {
      setFormattedValue(
        el[fieldName]?.toLocaleString('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }) || ''
      );
    }, [el, fieldName]);
  
    const handleInputChange = (e) => {
      let rawValue = e.target.value.replace(',', '.');
      if (/^\d*\.?\d*$/.test(rawValue)) {
        const numberValue = parseFloat(rawValue);
        if (!isNaN(numberValue) && numberValue >= 0 && numberValue <= 100) {
          setFormattedValue(rawValue);
          handleChange(fieldName, numberValue, el.id);
        } else if (rawValue === '') {
          setFormattedValue('');
          handleChange(fieldName, 0, el.id);
        }
      }
    };
  
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5, marginBottom: 10 }}>
        <span style={{ fontSize: 11, color: "#999999" }}>Процент от конверсий</span>
        <input
          onChange={handleInputChange}
          value={formattedValue}
          type="text"
          style={{
            height: 25,
            border: "1px solid #d5d5d5",
            width: "70px",
            padding: "13px",
          }}
        />
      </div>
    );
  };

  
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <h4>Выбор начальной цели</h4>
        <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          <GoalPicker goalsList={goalsList} fieldName="post_views_start_goal_id" defaultValue={campaignData.post_views_start_goal_id} handleChange={handleChange} campaignId={campaignData.id}/>
          <FormatNumberInput el={campaignData} fieldName="post_views_start_percent" handleChange={handleChange} />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <h4>Выбор конечной цели</h4>
        <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          <GoalPicker goalsList={goalsList} fieldName="post_views_end_goal_id" defaultValue={campaignData.post_views_end_goal_id} handleChange={handleChange} campaignId={campaignData.id}/>
          <FormatNumberInput el={campaignData} fieldName="post_views_end_percent" handleChange={handleChange} />
        </div>
      </div>
    </div>
  );
};


const PrgStatsData = ({campaignData}) => {
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [filterArr, setFilter] = useState([]);
  const [upd, setUpd] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      let date_start, date_end;
      if (filtersValue.date.date_start === "" || filtersValue.date.date_end === "") {
        date_end = Math.floor(new Date().getTime() / 1000);
        date_start = Math.floor(date_end - (7 * 24 * 60 * 60 * 1000) / 1000);
      } else {
        date_start = Math.floor((new Date(filtersValue.date.date_start).getTime() / 1000 + 86400));
        date_end = Math.floor((new Date(filtersValue.date.date_end).getTime() / 1000) + 86400);
      }
      const reachResponse = await fetch(`${API_HOST}/api/v1/ssp-stats/${campaignData.id}?target_type=REACH&date_from=${date_start}&date_to=${date_end}${filtersValue.partner_name ? `&partner_name=${filtersValue.partner_name}` : ""}`, {
        method: "GET",
        headers: myHeaders,
      });
      const trafficResponse = await fetch(`${API_HOST}/api/v1/ssp-stats/${campaignData.id}?target_type=TRAFFIC&date_from=${date_start}&date_to=${date_end}${filtersValue.partner_name ? `&partner_name=${filtersValue.partner_name}` : ""}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (reachResponse.ok && trafficResponse.ok) {
        const reachData = await reachResponse.json();
        const trafficData = await trafficResponse.json();
        const combinedData = reachData.map(reachItem => {
          const correspondingTrafficItem = trafficData.find(trafficItem => trafficItem.date === reachItem.date);
          return {
            date: reachItem.date,
            clicks: correspondingTrafficItem ? correspondingTrafficItem.amount : 0,
            views: reachItem.amount || 0,
          };
        });
        setFilter(combinedData);
      }
    }
    fetchData();
  }, [upd]);

  useEffect(() => {
    // if (filtersValue.date.date_start === "" || filtersValue.date.date_end === "") return;
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterStatsDate(value, acc);
          case "partner_name":
            if (value === "") return acc;
            return filterStatsPartner(value, acc);            
          default:
            return acc;
        }
      },
      [...filterArr]
    );
    setFilter(result);
  }, [filtersValue]);

  const columns = [
    {
      Header: "Просмотры",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Показы",
      accessor: "views",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата",
      accessor: "date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    }
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
    setUpd(!upd);
  };

  function getTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA > dateB ? -1 : 1;
    });
  
    return sortedData.map((el) => {
      return {
        clicks: <div className="td">{el.clicks ? el.clicks : "-"}</div>,
        views: <div className="td">{el.views ? el.views : "-"}</div>,
        date: <div className="td">{el.date ? dateFormat(Date.parse(el.date), "dd/MM/yyyy") : "-"}</div>
      };
    });
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
      <Box sx={{ width: 175 }}>
        <FormControl fullWidth size="small">
          <InputLabel
            id="demo-select-small-label"
            sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
          >
            Партнёр
          </InputLabel>
          <Select
            style={{
              height: 32,
              background: "#FFFFFF",
              borderColor: "transparent",
            }}
            sx={{
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
            label="Партнёр"
            id="demo-select-small-label"
            value={filtersValue.partner_name}
            onChange={(e) => {
              console.log(e.target.value);
              setValue((prev) => ({ ...prev, partner_name: e.target.value }))
              setUpd(!upd);
            }}
          >
            <MenuItem value={""}>Все партнёры</MenuItem>
            <MenuItem value={"marketplace-su"}>Market-place.su</MenuItem>
            <MenuItem value={"contema"}>Contema</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <DataTable data={getTableData()} columns={columns} />
    </div>
  )
}


const OpenModal = ({ openModal, setOpenModal, type, creativeData, campaignData }) => {
  let modalContent;

  const ModalStats = ({ openModal, setOpenModal, children }) => {
    const closeModal = () => setOpenModal(false);
  
    const modalStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: openModal ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    };
  
    const modalContentStyles = {
      backgroundColor: '#fff',
      width: '80%',
      maxWidth: 400,
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    return (
      <div style={modalStyles} onClick={closeModal}>
        <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  };

  switch (type) {
    case "banners_stats":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={defaultStyles}>
            <h3 style={{ fontSize: 18 }}>
              Статистика баннеров по кампании - {campaignData.name}
            </h3>
            {Object.keys(campaignData).length !== 0 && <BannersStatsData campaignData={campaignData}/>}
          </Box>
          
        </ModalStats>
      );
      break;

    case "postview_settings":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={defaultStyles}>
            <h3 style={{ fontSize: 18 }}>
              PostView настройка по кампании - {campaignData.name}
            </h3>
            {Object.keys(campaignData).length !== 0 && <SettingsPostView campaignData={campaignData}/>}
          </Box>
          
        </ModalStats>
      );
      break;

    case "prg_stats":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={decisionStyles}>
            <h3 style={{ fontSize: 18 }}>
              Статистика с Programmatic по кампании - {campaignData.name}
            </h3>
            {Object.keys(campaignData).length !== 0 && <PrgStatsData campaignData={campaignData}/>}
          </Box>
          
        </ModalStats>
      );
      break;

    case "open_creative":
      modalContent = (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Закрепленные баннеры
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <div style={{ width: "100%", height: "100%", display: "flex", gap: 10, marginTop: 10}}>
                {
                  creativeData.icon ? (
                    <div style={{ width: 100, height: 100, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                      <img src={`${API_HOST}/${creativeData.icon.banner_path}`} alt="img" style={{ width: 220, height: 220, borderRadius: 10, objectFit: "contain" }} />
                    </div>
                  ) : (
                    <div style={{ width: 100, height: 100, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                  )
                }
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                  {
                    creativeData.banner ? (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                        {
                          creativeData.banner.banner_path.match(/\.(mp4|webm|ogg)$/i) ? (
                            <video
                              src={`${API_HOST}/${creativeData.banner.banner_path}`}
                              style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: "contain" }}
                              controls
                            />
                          ) : (
                            <img
                              src={`${API_HOST}/${creativeData.banner.banner_path}`}
                              alt="img"
                              style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: "contain" }}
                            />
                          )
                        }
                      </div>
                    ) : (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                    )
                  }
                  {
                    creativeData.overlay ? (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                        <img src={`${API_HOST}/${creativeData.overlay.banner_path}`} alt="img" style={{ width: 340, height: 190, borderRadius: 10, objectFit: "contain" }} />
                      </div>
                    ) : (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                    )
                  }
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <h3>{creativeData.head}</h3>
                <p>{creativeData.text}</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <h3>Реферальные ссылки:</h3>
                {
                  creativeData.icon && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>256x256 icon:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/icon/PRG</p>
                    </div>
                  )
                }
                {
                  creativeData.banner && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>600x600 banner:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/banner/PRG</p>
                    </div>
                  )
                }
                {
                  creativeData.overlay && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>1080x607 overlay:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/overlay/PRG</p>
                    </div>
                  )
                }
              </div>
            </div>
          </Box>
        </Modal>
      )
      break;
    
      case "open_campaign":
        modalContent = (
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={campaignStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Данные РК
              </Typography>
              <Box
                style={{ gap: 20, marginBottom: 30, marginTop: 10 }}
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                {Object.keys(campaignData).length !== 0 && <CampaignData data={campaignData}/>}
              </Box>
            </Box>
          </Modal>
        )
        break;

        case "post_view":
          console.log(campaignData)
          modalContent = (
            <ModalStats
              openModal={openModal}
              setOpenModal={() => setOpenModal(false)}
            >
              <Box sx={postviewStyles}>
                <h3 style={{ fontSize: 18 }}>
                  Post-view отчёт по кампании - {campaignData.name}
                </h3>
                {Object.keys(campaignData).length !== 0 && <PostViewData campaignData={campaignData} />}
              </Box>
            </ModalStats>
          );
        break;


    default:
      modalContent = null;
  }

  return modalContent;
};

function Reach() {
  const [filterArr, setFilter] = useState([]);
  const [arrCampaigns, setCampaigns] = useState([]);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [creativeData, setCreativeData] = useState({});
  const [campaignData, setCampaignData] = useState({});
  const debounceTimers = useRef({});
  const updateQueues = useRef({});
  const isUpdating = useRef({});
  const [upd, setUpd] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/ready_for_ssp`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setCampaigns(data);
        setFilter(data);
      }
    }
    fetchData();
  }, [upd]);

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "full_name":
            if (value === "") return acc;
            return filterEmploye(value, acc);
          default:
            return acc;
        }
      },
      [...arrCampaigns]
    );
    setFilter(result);
  }, [filtersValue, arrCampaigns]);

  const fetchCreatives = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/banners`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.json();
      setCreativeData(data[0]);
    }
  }

  const fetchCampaign = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.json();
      setCampaignData(data);
    }
  }


  
  const FormatNumberInput = ({ el, handleChange, fieldName }) => {
    const [formattedValue, setFormattedValue] = useState('');

    useEffect(() => {
      setFormattedValue(el[fieldName].toLocaleString('ru-RU'));
    }, [el, fieldName]);

    const handleInputChange = (e) => {
      const rawValue = e.target.value.replace(/\D/g, '');
      const numberValue = Number(rawValue);
      
      const formatted = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      setFormattedValue(formatted);
      handleChange(fieldName, numberValue, el.id);
    };

    return (
      <div className="td">
        <input
          onChange={handleInputChange}
          defaultValue={el[fieldName]}
          value={formattedValue}
          type="text"
          style={{
            height: 25,
            border: "1px solid #d5d5d5",
            width: "100%",
            padding: "10px"
          }}
        />
      </div>
    );
  };


  const debounceUpdate = (field, campaign_id, callback, delay = 500) => {
    const uniqueKey = `${field}-${campaign_id}`;
    clearTimeout(debounceTimers.current[uniqueKey]);

    debounceTimers.current[uniqueKey] = setTimeout(() => {
        if (!updateQueues.current[uniqueKey]) updateQueues.current[uniqueKey] = [];
        updateQueues.current[uniqueKey].push(callback);
        processUpdateQueue(uniqueKey);
    }, delay);
  };

  const processUpdateQueue = async (uniqueKey) => {
      if (isUpdating.current[uniqueKey] || !updateQueues.current[uniqueKey] || updateQueues.current[uniqueKey].length === 0) return;

      isUpdating.current[uniqueKey] = true;

      while (updateQueues.current[uniqueKey].length > 0) {
          const callback = updateQueues.current[uniqueKey].shift();
          await callback();
      }

      isUpdating.current[uniqueKey] = false;
  };

  const handleChange = (field, value, campaign_id) => {
      debounceUpdate(field, campaign_id, async () => {
          console.log(field, value, campaign_id);
          await updateFields({ [field]: value }, campaign_id);
      });
  };

  const updateFields = async (fields, campaign_id) => {
      if (campaign_id === null) return;
      console.debug(fields, campaign_id);
      await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/update_ssp`, {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
          },
          body: JSON.stringify(fields),
      });
  };

  const columns = [
    {
      Header: "Название РК",
      accessor: "campaign_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
      isSticky: true,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Почта клиента",
      accessor: "email",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Открыть креативы",
      accessor: "open_creative",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Открыть РК",
      accessor: "open_campaign",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Post-view отчёт",
      accessor: "post_view",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Статистика по баннерам",
      accessor: "banners_stats",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Настройка post-view",
      accessor: "postview_settings",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Установка гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Максимальный CPM",
      accessor: "ssp_plan_cpm",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Плановые показы",
      accessor: "ssp_plan_views_count",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Дневной лимит по показам",
      accessor: "ssp_views_count_day_limit",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Факт. показов в день",
      accessor: "ssp_views_count_day",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Всего показов",
      accessor: "ssp_views_count",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Частота показов",
      accessor: "ssp_show_frequency",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Дата начала цикла",
      accessor: "start_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата окончания цикла",
      accessor: "end_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Статус кампании",
      accessor: "ssp_active",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сброс статистики",
      accessor: "reset",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Удалить с SSP",
      accessor: "delete_from_ssp",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    }
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  const handleStatsReset = async (campaign_id) => {
    return await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/ssp_reset`, {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
      },
    });
  }

  const handleCampaignRemove = async (campaign_id) => {
    return await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/ssp_remove`, {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
      },
    });
  }

  function getTableData() {
    return filterArr.map((el) => {
      return {
        campaign_name: <div className="td">{el.name}</div>,
        email: <div className="td">{el.campaign_creator_email}</div>,
        site: <div className="td">{el.site}</div>,
        started_date: <div className="td">{dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}</div>,
        geo: <RegionSelect defaultValue={el.ssp_geo} regions={regionsData} handleChange={handleChange} campaign_id={el.id} />,
        open_creative: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              fetchCreatives(el.id)
              setOpenModal(true)
              setModalType("open_creative")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        open_campaign: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("open_campaign")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        post_view: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("post_view")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        banners_stats: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("banners_stats")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        postview_settings: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("postview_settings")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        ssp_plan_cpm: (
          <FormatNumberInput
            el={el}
            fieldName="ssp_plan_cpm"
            handleChange={handleChange}
          />
        ),
        ssp_views_count: (
          <div className="td">
            {el.ssp_views_count.toLocaleString('ru-RU')}
          </div>
        ),
        ssp_show_frequency: (
          <FormatNumberInput
            el={el}
            fieldName="ssp_show_frequency"
            handleChange={handleChange}
          />
        ),
        ssp_plan_views_count: (
          <FormatNumberInput
            el={el}
            fieldName="ssp_plan_views_count"
            handleChange={handleChange}
          />
        ),
        ssp_views_count_day: (
          <div className="td">
            {el.ssp_views_count_day.toLocaleString('ru-RU')}
          </div>
        ),
        ssp_views_count_day_limit: (
          <div className="td">
            {el.ssp_views_count_day_limit.toLocaleString('ru-RU')}
          </div>
        ),
        start_cycle_date: (
          <div className="td" style={{ height: 40 }}>
            <DatePickerTest
              defaultValue={el.ssp_date_start}
              onChange={(date) => {
                const formattedDate = new Date(date).toISOString().split('T')[0];
                handleChange('ssp_date_start', formattedDate, el.id);
              }}
            />
          </div>
        ),
        end_cycle_date: (
          <div className="td" style={{ height: 40 }}>
            <DatePickerTest
              defaultValue={el.ssp_date_end}
              onChange={(date) => {
                const formattedDate = new Date(date).toISOString().split('T')[0];
                handleChange('ssp_date_end', formattedDate, el.id);
              }}
            />
          </div>
        ),
        ssp_active: (
          <div className="td">
            <Switch defaultChecked={el.ssp_active} onChange={(e) => handleChange('ssp_active', e.target.checked, el.id)} />
          </div>
        ),
        reset: (
          <button
          style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", fontSize: 14, borderRadius: 5 }}
          onClick={async () => {
            const answer = await confirm("Вы действительно хотите сбросить статистику?")
            if (!answer) return;
            const res = await handleStatsReset(el.id)
            if (res.status === 200) {
              enqueueSnackbar("Статистика успешно сброшена", {
                variant: "success",
                autoHideDuration: 1000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              setUpd((prev) => !prev);
            } else {
              enqueueSnackbar("Произошла ошибка", {
                variant: "error",
                autoHideDuration: 1000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          }}>
            Сбросить
          </button>
        ),
        delete_from_ssp: (
          <button
          style={{ width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", fontSize: 14, borderRadius: 5 }}
          onClick={async () => {
            const answer = await confirm("Вы действительно хотите удалить кампанию с таблицы ?")
            if (!answer) return;
            const res = await handleCampaignRemove(el.id)
            if (res.status === 200) {
              enqueueSnackbar("Кампания успешно удалена с таблицы", {
                variant: "success",
                autoHideDuration: 1000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              setUpd((prev) => !prev);
            } else {
              enqueueSnackbar("Произошла ошибка", {
                variant: "error",
                autoHideDuration: 1000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          }}>
            Удалить
          </button>
        )
      };
    });
  }
  return (
    <div className="logs" style={{ width: "100%", height: "97%" }}>
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", height: "94%", overflowY: "auto" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} creativeData={creativeData} campaignData={campaignData}/>
    </div>
  );
}

export default Reach;
