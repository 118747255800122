import { useState } from "react";
import DataTable from "../Table/Datatable";
import { format as dateFormat } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import "./dsp.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye } from "./filters";
import { Modal, Box, Typography } from "@material-ui/core";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const styles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const decisionStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const campaignStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};


const CampaignData = ({data}) => {
  const { enqueueSnackbar } = useSnackbar();
  const listCampaigns = []
  listCampaigns.push(data)

  const columns = [
    {
      Header: "ID РК",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Название",
      accessor: "name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Почта клиента",
      accessor: "campaign_creator_email",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Дата создания",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Тип РК",
      accessor: "type",
      maxWidth: 400,
      minWidth: 60,
      width: 117,
    },
    {
      Header: "Дневной бюджет",
      accessor: "daily_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Общий бюджет",
      accessor: "full_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Ставка",
      accessor: "bet",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Период с-по",
      accessor: "range",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сумма спис",
      accessor: "charge",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 400,
      minWidth: 50,
      width: 200,
    },
  ];

  const STATUS_TYPE = {
    ACTIVE: "На модерации",
    INACTIVE: "Неактивна",
    WORK: "Работает",
    MODERATION: "На модерации",
    ERROR: "Ошибка",
  };
  
  const TARGET_TYPE = {
    TRAFFIC: "Трафик",
    REACH: "Охват",
  };

  function getTableData() {
    return listCampaigns.map((el) => {
      return {
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            {el.id.slice(0, 8)}
            <ContentCopy
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(el.id);
                enqueueSnackbar("ID кампании скопирован", {
                  variant: "success",
                  autoHideDuration: 1000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }}
              className="copyIcon"
              fontSize="small"
            />
          </div>
        ),
        name: <div className="td">{el.name ? el.name : "-"}</div>,
        site: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.site ? el.site : "-"}</span>
            {el.site && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.site);
                  enqueueSnackbar("Сайт кампании скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        campaign_creator_email: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.campaign_creator_email ? el.campaign_creator_email : "-"}</span>
            {el.campaign_creator_email && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.campaign_creator_email);
                  enqueueSnackbar("Почта клиента скопирована", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        created_at: (
          <div className="td">
            {dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}
          </div>
        ),
        type: <div className="td">{TARGET_TYPE[el.target_type]}</div>,
        daily_budget: <div className="td">{el.daily_budget}</div>,
        full_budget: <div className="td">{el.full_budget}</div>,
        bet: <div className="td">{el.price_per_action}</div>,
        range: (
          <div className="td">
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_start)),
              "dd/MM/yyyy"
            )}
            -
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_end)),
              "dd/MM/yyyy"
            )}
          </div>
        ),
        charge: <div className="td">{el.charges_sum}</div>,
        status: (
          <div className="td">
            {STATUS_TYPE[el.status]}
          </div>
        ),
      };
    });
  }

  return (
    <DataTable data={getTableData()} columns={columns} />
  )
}


const OpenModal = ({ openModal, setOpenModal, type, creativeData, campaignData, upd, setUpd }) => {
  let modalContent;
  const { enqueueSnackbar } = useSnackbar();

  const [erid, setErid] = useState("");
  const [canApprove, setCanApprove] = useState(false);

  const rejectCampaign = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/${campaign_id}/reject`, {
      method: "POST",
      headers: myHeaders,
    });
    if (res.ok) {
      setUpd(!upd);
    }
  }

  const aproveCampaign = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/${campaign_id}/approve`, {
      method: "POST",
      headers: myHeaders,
    });
    if (res.ok) {
      setUpd(!upd);
    }
  }

  switch (type) {
    case "decision":
      modalContent = (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={decisionStyles}>
            <h3 style={{ fontSize: 18 }}>
              Решение по рекламной компании - {campaignData.name}
            </h3>
            <p style={{ fontSize: 14, marginTop: 10, fontWeight: 400 }}>
              На рассмотрение была отправлена новая рекламная кампания. Решите, одобрить или отклонить кампанию.
            </p>
            <div style={{ display: "flex", gap: 10, marginTop: 18, alignItems: "flex-end", marginBottom: 20 }}>
              <div style={{ width: "100%"}}>
                <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Укажите erid из ОРД</p>
                <input placeholder="Идентификатор креатива" style={{ width: "100%", border: "1px solid #e4e4e7", borderRadius: 5 }} type="text" onChange={(e) => setErid(e.target.value)} />
              </div>
              <button
                onMouseEnter={(e) => { e.target.style.backgroundColor = "#f3f3f4"; }}
                onMouseLeave={(e) => { e.target.style.backgroundColor = "transparent"; }}
                style={{ fontSize: 14, padding: "8px 16px", borderRadius: 5, border: "1px solid #e4e4e7", transition: "background-color 0.3s ease", height: 33}}
                onClick={() => {
                  const fetchApi = async () => {
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append(
                      "Authorization",
                      "Bearer " + localStorage.getItem("admin_token")
                    );
                    const res = await fetch(`${API_HOST}/api/v1/admin/${campaignData.id}/erid?erid=${erid}`, {
                      method: "POST",
                      headers: myHeaders
                    });
                    if (res.ok) {
                      setCanApprove(true)
                      enqueueSnackbar("Креатив с erid - " + erid + " успешно найден", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                      });
                    } else {
                      setCanApprove(false)
                      enqueueSnackbar("Произошла проблема, проверьте корректность erid или инн клиента.", {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                      });
                    }
                  };
                  fetchApi();
                }}>
                Проверить
              </button>
            </div>
            <div style={{ display: "flex", gap: 10, marginTop: 18, justifyContent: "flex-end" }}>
              <button
                onMouseEnter={(e) => { e.target.style.backgroundColor = "#f3f3f4"; }}
                onMouseLeave={(e) => { e.target.style.backgroundColor = "transparent"; }}
                style={{ fontSize: 14, padding: "8px 16px", borderRadius: 5, border: "1px solid #e4e4e7", transition: "background-color 0.3s ease"}}
                onClick={() => {
                  rejectCampaign(campaignData.id)
                  setOpenModal(false)
                }}>
                Отклонить
              </button>
              <button
                onMouseEnter={(e) => { e.target.style.backgroundColor = "#22cd8d"; }}
                onMouseLeave={(e) => { e.target.style.backgroundColor = "#1dad77"; }}
                style={{ color: "#fff", fontSize: 14, backgroundColor: "#1dad77", padding: "8px 16px", borderRadius: 5, transition: "background-color 0.3s ease"}}
                onClick={() => {
                  if (!canApprove) {
                    enqueueSnackbar("Обязательно укажите erid и проверьте его", {
                      variant: "info",
                      autoHideDuration: 3000,
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                    return;
                  }
                  aproveCampaign(campaignData.id)
                  setOpenModal(false)
                  enqueueSnackbar("Кампания одобрена.", {
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}>
                Одобрить
              </button>
            </div>
          </Box>
        </Modal>
      );
      break;
    
    case "open_creative":
      modalContent = (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Закрепленные баннеры
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <div style={{ width: "100%", height: "100%", display: "flex", gap: 10, marginTop: 10}}>
                {
                  creativeData.icon ? (
                    <div style={{ width: 100, height: 100, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                      <img src={`${API_HOST}/${creativeData.icon.banner_path}`} alt="img" style={{ width: 220, height: 220, borderRadius: 10, objectFit: "contain" }} />
                    </div>
                  ) : (
                    <div style={{ width: 100, height: 100, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                  )
                }
                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                  {
                    creativeData.banner ? (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                        {
                          creativeData.banner.banner_path.match(/\.(mp4|webm|ogg)$/i) ? (
                            <video
                              src={`${API_HOST}/${creativeData.banner.banner_path}`}
                              style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: "contain" }}
                              controls
                            />
                          ) : (
                            <img
                              src={`${API_HOST}/${creativeData.banner.banner_path}`}
                              alt="img"
                              style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: "contain" }}
                            />
                          )
                        }
                      </div>
                    ) : (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                    )
                  }
                  {
                    creativeData.overlay ? (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}>
                        <img src={`${API_HOST}/${creativeData.overlay.banner_path}`} alt="img" style={{ width: 340, height: 190, borderRadius: 10, objectFit: "contain" }} />
                      </div>
                    ) : (
                      <div style={{ width: 340, height: 190, background: "#dee7ff", border: "1px solid #3f73f9"}}></div>
                    )
                  }
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <h3>{creativeData.head}</h3>
                <p>{creativeData.text}</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <h3>Реферальные ссылки:</h3>
                {
                  creativeData.icon && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>256x256 icon:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/icon/PRG</p>
                    </div>
                  )
                }
                {
                  creativeData.banner && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>600x600 banner:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/banner/PRG</p>
                    </div>
                  )
                }
                {
                  creativeData.overlay && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <p style={{ fontSize: 15, fontWeight: 600 }}>1080x607 overlay:</p>
                      <p style={{ fontFamily: "monospace" }}>https://api.programmatic.ru/api/v1/staticbanners/{campaignData.id}/overlay/PRG</p>
                    </div>
                  )
                }
              </div>
            </div>



          </Box>
        </Modal>
      )
      break;
    
      case "open_campaign":
        modalContent = (
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={campaignStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Данные РК
              </Typography>
              <Box
                style={{ gap: 20, marginBottom: 30, marginTop: 10 }}
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                {Object.keys(campaignData).length !== 0 && <CampaignData data={campaignData}/>}
              </Box>
            </Box>
          </Modal>
        )
        break;

    default:
      modalContent = null;
  }

  return modalContent;
};

function Dsp() {
  const [filterArr, setFilter] = useState([]);
  const [arrCampaigns, setCampaigns] = useState([]);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [creativeData, setCreativeData] = useState({});
  const [campaignData, setCampaignData] = useState({});
  const [upd, setUpd] = useState(false);
  
  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/on-moderation`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setCampaigns(data);
        setFilter(data);
      }
    }
    fetchData();
  }, [upd]);

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "full_name":
            if (value === "") return acc;
            return filterEmploye(value, acc);
          default:
            return acc;
        }
      },
      [...arrCampaigns]
    );
    setFilter(result);
  }, [filtersValue, arrCampaigns]);

  const fetchCreatives = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}/banners`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.json();
      setCreativeData(data[0]);
    }
  }

  const fetchCampaign = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      const data = await res.json();
      setCampaignData(data);
    }
  }

  const columns = [
    {
      Header: "Название РК",
      accessor: "campaign_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Почта клиента",
      accessor: "email",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата создания РК",
      accessor: "created_date",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Открыть креативы",
      accessor: "open_creative",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Открыть РК",
      accessor: "open_campaign",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Принять решение по РК",
      accessor: "decision",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  function getTableData() {
    return filterArr.map((el) => {
      return {
        campaign_name: <div className="td">{el.name}</div>,
        email: <div className="td">{el.campaign_creator_email}</div>,
        site: <div className="td">{el.site}</div>,
        created_date: <div className="td">{dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}</div>,
        open_creative: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              fetchCreatives(el.id)
              setOpenModal(true)
              setModalType("open_creative")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        open_campaign: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("open_campaign")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        decision: (
          <button
            onClick={() => {
              fetchCampaign(el.id)
              setOpenModal(true)
              setModalType("decision")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        )
      };
    });
  } 
  return (
    <div className="logs" style={{ width: "100%", height: "97%" }}>
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", overflowY: "auto", height: "94%" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} creativeData={creativeData} campaignData={campaignData} upd={upd} setUpd={setUpd}/>
    </div>
  );
}

export default Dsp;
