import {CategoryStatistics} from "..";

function MetricsStatistics({ data }) {
  const label = ["Показы","Клики","Лиды","Расход (₽)","CTR (%)","CPC ( ₽)","eCPM ( ₽)","CR (%)","CPA" ]
  function numberWithSpaces(number) {
    if(number < 10000) return number
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  function numberToFixcedWithSpaces(number) {
    var parts = number.toString().split(".");
    if(number < 10000) return number
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }
    return (
        <div className="container" style={{ transform: "scale(0.92)", display: "flex", justifyContent: "center", marginBottom: 0 }}>
          {data?.map((value,key) => 
            <CategoryStatistics
              title={label[key]}
              key={label[key]}
              amount={(value!==null) ? [0,1,2].some((el) => el === key) ? numberWithSpaces(value.toFixed(0)) : numberToFixcedWithSpaces(value.toFixed(2)) : "-"}
            />)}
        </div>
      );
}

export default MetricsStatistics;